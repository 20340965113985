import React from 'react'
import Logo from "../assets/logo.png";

export default function LogoComponent() {
  return (
    <div className="LogoComponent-container">
    <img src={Logo} alt="Maa Consultancy" className="LogoComponent-image" />
  </div>
  )
}
