import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Logout } from "./actions/authActions"; // Import Logout action
import { useDispatch } from "react-redux";
import FormAdd from "./components/Form/FormAdd";
import UserMaster from "./components/UserMaster/UserMaster";
import RoleTemplate from "./components/RoleTemplate/RoleTemplate";
import api from "./Utils/api";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import LoaderWithLogo from "./Common/LoaderWithLogo";
import ClientMaster from "./components/ClientMaster/ClientMaster";
import HospitalMaster from "./components/HospitalMaster/HospitalMaster";
import InsuranceCompany from "./components/InsuranceCompany/InsuranceCompany";
import CourierStatusMaster from "./components/CourierStatusMaster/CourierStatusMaster";
import CourierNameMaster from "./components/CourierNameMaster/CourierNameMaster";
import TPAMaster from "./components/TPAMaster/TPAMaster";
import PolicyType from "./components/PolicyType/PolicyType";
import DocumentType from "./components/DocumentTypes/DocumentTypes";
import Courier from "./components/Courier/Courier";
import IntimationStatusMaster from "./components/IntimationStatusMaster/IntimationStatusMaster";
import Policy from "./components/Policy/Policy";
import UserProfilePopover from "./Header/UserProfilePopover";
import SMSApi from "./components/SMSApi/SMSApi";
import Endorsement from "./components/Endorsement/Endorsement";
import SMSTemplate from "./components/SMSTemplate/SMSTemplate";
import SMSSend from "./components/SMSSend/SMSSend";
import SMSHistory from "./components/SMSHistory/SMSHistory";
import StatusOfWhatsApp from "./components/StatusOfWhatsApp/StatusOfWhatsApp";
import InsuranceClaim from "./components/InsuranceClaim/InsuranceClaim";
import EmailTemplate from "./components/EmailTemplate/EmailTemplate";
import AilmentMaster from "./components/AilmentMaster/AilmentMaster";
import AgentMaster from "./components/AgentMaster/AgentMaster";
import Dashboard from "./components/DashBoard/DashBoard";
import LogoComponent from "./Common/LogoComponent";

const routeConfig = [
  { path: "/FormAdd", component: FormAdd, permission: "FormAdd" },
  { path: "/UserMaster", component: UserMaster, permission: "UserMaster" },
  { path: "/ClientMaster", component: ClientMaster, permission: "ClientMaster" },
  { path: "/HospitalMaster", component: HospitalMaster, permission: "HospitalMaster" },
  { path: "/InsuranceCompany", component: InsuranceCompany, permission: "InsuranceCompany" },
  { path: "/TPAMaster", component: TPAMaster, permission: "TPAMaster" },
  { path: "/PolicyType", component: PolicyType, permission: "PolicyType" },
  { path: "/DocumentType", component: DocumentType, permission: "DocumentType" },
  { path: "/CourierStatusMaster", component: CourierStatusMaster, permission: "CourierStatusMaster" },
  { path: "/CourierNameMaster", component: CourierNameMaster, permission: "CourierNameMaster" },
  { path: "/IntimationStatusMaster", component: IntimationStatusMaster, permission: "IntimationStatusMaster" },
  { path: "/Courier", component: Courier, permission: "Courier" },
  { path: "/Policy", component: Policy, permission: "Policy" },
  { path: "/UserProfile", component: UserProfilePopover, permission: "UserProfile" },
  { path: "/SMSApi", component: SMSApi, permission: "SMSApi" },
  { path: "/SMSTemplate", component: SMSTemplate, permission: "SMSTemplate" },
  { path: "/SMSSend", component: SMSSend, permission: "SMSSend" },
  { path: "/SMSHistory", component: SMSHistory, permission: "SMSHistory" },
  { path: "/Endorsement", component: Endorsement, permission: "Endorsement" },
  { path: "/StatusOfWhatsApp", component: StatusOfWhatsApp, permission: "StatusOfWhatsApp" },
  { path: "/InsuranceClaim", component: InsuranceClaim, permission: "InsuranceClaim" },
  { path: "/EmailTemplate", component: EmailTemplate, permission: "EmailTemplate" },
  { path: "/AilmentMaster", component: AilmentMaster, permission: "AilmentMaster" },
  { path: "/AgentMaster", component: AgentMaster, permission: "AgentMaster" },
  { path: "/Dashboard", component: Dashboard, permission: "Dashboard" },

  {
    path: "/RoleTemplate",
    component: RoleTemplate,
    permission: "RoleTemplate",
  },
];

const HandleLogout = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(Logout());
    window.location.href = "/";
  };
  React.useEffect(() => {
    handleLogout();
  }, []);
  return null;
};

export default function RoutesComponent() {
  const [rolePermissionData, setRolePermissionData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRolePermission();
  }, []);

  const getRolePermission = () => {
    setLoading(true);
    api
      .get("RoleTemplateDetail/ViewPermissionWiseFormShow")
      .then((result) => {
        setRolePermissionData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      });
  };

  const hasPermission = (permissionName) => {
    debugger
    return rolePermissionData.some(
      (item) => item.actionName === permissionName && item.viewPermission
    );
  };

  return (
    <>
      {loading && <LoaderWithLogo />}

      <Routes>
        {routeConfig.map(
          (route) =>
            hasPermission(route.permission) && (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              />
            )
        )}
        <Route path="/logout" element={<HandleLogout />} />
        <Route path="*" element={ hasPermission("Dashboard") ? <Dashboard /> : <LogoComponent />} />
      </Routes>
    </>
  );
}
