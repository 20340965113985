import React from "react";
import api, { handleApiError } from "./api";
import axios from "axios";
import jsPDF from "jspdf";

export function convertDateFormatWithOutTime(dateString) {
  const dateObject = new Date(dateString);

  // Extract date, month, year, hours, and minutes
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear();
  const formattedDate = `${day}/${month}/${year} `;

  return formattedDate;
}

export function getDayName(dateString) {
  const dateObject = new Date(dateString);

  // Check if the input date is valid
  if (isNaN(dateObject)) return "Invalid date";

  // Get the current year
  const year = new Date().getFullYear();

  // Create a new Date object with the same day and month, but current year
  const formattedDate = new Date(year, dateObject.getMonth(), dateObject.getDate());

  // Get the day of the week in 'long' format (e.g., Monday, Tuesday)
  return formattedDate.toLocaleDateString('en-US', { weekday: 'long' });
}


export function convertDateFormat(dateString) {
  const dateObject = new Date(dateString);

  // Extract date, month, year, hours, minutes, and seconds
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear();
  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  const seconds = dateObject.getSeconds().toString().padStart(2, "0");
  let ampm = "AM";

  // Convert 24-hour time to 12-hour time and determine AM/PM
  if (hours >= 12) {
    ampm = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  }

  // Format the date string including seconds
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;

  return formattedDate;
}

export const getCurrentDateTime = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");

  return `${day}-${month}-${year}T${hours}:${minutes}`;
};

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: value % 1 === 0 ? 0 : 0,
  }).format(value);

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const GetFutureDate = () => {
  const today = new Date();
  const year = today.getFullYear() + 1;

  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate() - 1).padStart(2, "0");
  return `${year}-${month}-${day}`;
};


export const GetPre7DayDate = () => {
  const today = new Date();
  const pre7DayDate = new Date(today.setDate(today.getDate() - 7));

  const year = pre7DayDate.getFullYear();
  const month = String(pre7DayDate.getMonth() + 1).padStart(2, "0");
  const day = String(pre7DayDate.getDate()).padStart(2, "0");
  
  return `${year}-${month}-${day}`;
};

export const GetPre30DayDate = () => {
  const today = new Date();
  const pre30DayDate = new Date(today.setDate(today.getDate() - 30));

  const year = pre30DayDate.getFullYear();
  const month = String(pre30DayDate.getMonth() + 1).padStart(2, "0");
  const day = String(pre30DayDate.getDate()).padStart(2, "0");
  
  return `${year}-${month}-${day}`;
};



export const DayDifference = (Expected) => {
  const today = new Date();
  const currentDate = today.toISOString().split("T")[0];
  const ExpectedDate = Expected.split("T")[0];

  const timeDifference = new Date(currentDate) - new Date(ExpectedDate);

  const totalDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

  return totalDays < 0
    ? `${Math.abs(totalDays)} Days Remaining`
    : `${totalDays} Days OverDue`;
};

export const WhatsAppStatus = () => {
  
  return api
    .get(`SMSApi/WhatsAppStatus`)
    .then(({ data }) => data)
    .catch(handleApiError);
};

export const CheckRoleTemplatePermissions = (formName) => {
  return api
    .get(`RoleTemplateDetail/CheckRoleTemplatePermissions?FormName=${formName}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.error("Error fetching role template permissions:", error);
      return null;
    });
};

export const convertToPDF = (file) => {
  return new Promise((resolve, reject) => {
    // Check if the file is an image
    if (!file.type.includes("image")) {
      reject("File is not an image.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const pdf = new jsPDF();
        const imgWidth = 210; // mm
        const imgHeight = (img.height * imgWidth) / img.width; // Corrected line

        pdf.addImage(img, "JPEG", 0, 0, imgWidth, imgHeight);
        resolve(pdf.output("blob"));
      };
    };

    reader.readAsDataURL(file);
  });
};


export const appendIfNotNull = (form_Data, key, value) => {
  form_Data.append(key, value !== null && value !== undefined ? value : "");
};


export const getStringOrDefault = (value) => {
  return value === null || value === undefined ? "" : value;
};



export const filterParams = {
  comparator: function(filterLocalDateAtMidnight, cellValue) {
    if (!cellValue) return -1;
    const cellDate = new Date(cellValue);
    if (isNaN(cellDate)) return -1;
    const cellDateMidnight = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
    const filterDate = new Date(filterLocalDateAtMidnight.getFullYear(), filterLocalDateAtMidnight.getMonth(), filterLocalDateAtMidnight.getDate());
    if (filterDate.getTime() === cellDateMidnight.getTime()) {
      return 0;
    }
    if (cellDateMidnight < filterDate) {
      return -1;
    }
    if (cellDateMidnight > filterDate) {
      return 1;
    }
    return 0;
  },
  minValidYear: new Date().getFullYear() - 100, // Current year minus 100
  maxValidYear: new Date().getFullYear() + 100,
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};