import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkTokenValidity } from "./actions/authActions";
import RoutesComponent from "./RoutesComponent";
import { isLoggedIn } from "./actions/authActions";
import Login from "./components/LogIn/Login";
import Header from "./Header/Header";
import Loader from "./Common/Loader ";
import { useLoadingContext } from "./Loading/LoadingContext";
import { WhatsAppStatus } from "../src/Utils/commonFunction";
import axios from "axios";
import Swal from "sweetalert2";
import api from "./Utils/api";
import {
  decryptAndGetItemCookiesStorage,
  encryptAndSetItemCookiesStorage,
} from "./Utils/CookiesStorage";

const App = () => {
  const dispatch = useDispatch();
  const LoginLoading = useSelector((state) => state.auth.loading);
  const isAuthenticated = isLoggedIn();
  const { isLoading } = useLoadingContext();

      useEffect(() => {
        dispatch(checkTokenValidity());
      }, [dispatch]);

      useEffect(() => {
        debugger;
        const WhatsAppData = decryptAndGetItemCookiesStorage("WhatsAppStatus");
        if (WhatsAppData == true) {
          debugger;
          api
            .get(`SMSApi/WhatsAppStatus`)
            .then((WhatsAppStatus1) => {
              const { qrimg, status } = WhatsAppStatus1.data.data;
              if (WhatsAppStatus1.data.message === "false") {
                // setWhatsAppStatusModel(true);
                return null;
              } else {
                if (status != "READY") {
                  Swal.fire({
                    title:
                      "Your Whatsapp Is Disconnected. Would you like to connect now? If so, please press YES",
                    type: "warning",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.open("/StatusOfWhatsApp");
                    }
                  });
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching WhatsApp status:", error);
            });
          encryptAndSetItemCookiesStorage("WhatsAppStatus", false);
        }
      }, []);

  return (
    <div className="App">
      {LoginLoading && <Loader />}
      {isLoading && <Loader />}
      {isAuthenticated ? (
        <>
          <Header />
          <RoutesComponent />
        </>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
